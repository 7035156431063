// src/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from './Carousel';
import aboutimg from './assets/aboutimg.JPG';
import dippin from './assets/dippin.JPG';
import heimland from './assets/heimland.png';
import heimlandko from './assets/heimland_ko.png';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Home = () => {
  return (
    <main className="mt-0">
      {/* 기존 내용 유지 */}
      <section id="home" className="h-screen flex items-center justify-center bg-gray-100">
        <div className="w-full h-screen">
          <Carousel />
        </div>
      </section>

      <section id="about" className="h-screen flex flex-col md:flex-row bg-white">
        <div className="container mx-auto flex flex-col md:flex-row mt-20 items-center">
          <div className="md:w-2/5 p-8">
            <h2 className="text-4xl font-bold mb-4">About Us</h2>
            <p className="text-lg text-gray-600">
            Blisshouse Studio was founded to bring ideas that are creative and convenient to life. We create board games that can be enjoyed by people of all ages, and develop apps that maximize the convenience of everyday life.
            </p>
          </div>
          <div className="md:w-3/5 p-8">
            <img
              src={aboutimg}
              alt="About Us"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
      </section>

      <section id="product" className="h-screen flex flex-col items-center justify-center bg-gray-100">
        <div className="container mx-auto p-8 flex flex-col justify-center items-center">
          <div className="flex mb-8 justify-center">
            {/* <img
              src={dippin}
              alt="Product 1"
              className="w-1/4 h-full object-cover rounded-lg shadow-md"
            /> */}
            <Link to="/legionsofheimland" className="w-full h-auto flex justify-center items-center">
              <img
                src={heimland}
                alt="Product 2"
                className="w-1/2 h-auto object-cover rounded-lg shadow-md cursor-pointer"
              />
            </Link>
            <Link to="/legionsofheimlandko" className="w-full h-auto flex justify-center items-center">
              <img
                src={heimlandko}
                alt="Product 2"
                className="w-1/2 h-auto object-cover rounded-lg shadow-md cursor-pointer"
              />
            </Link>
          </div>
          <div className="text-center">
            <h2 className="text-4xl font-bold mb-4">Our Products</h2>
            <p className="max-w-xl text-center text-gray-600">
              Our very first board game is coming out soon!
            </p>
          </div>
        </div>
      </section>

      <section id="contact" className="h-screen flex flex-col items-center justify-center bg-white">
        <h2 className="text-4xl font-bold mb-4">Contact Us</h2>
        <div className="max-w-xl text-center text-gray-600 space-y-4">
          <div className="flex items-center justify-center space-x-2">
            <FaEnvelope className="text-2xl text-blue-500" />
            <p className="text-lg">info@blisshousestudio.net</p>
          </div>
          <div className="flex items-center justify-center space-x-2">
            <FaMapMarkerAlt className="text-2xl text-red-500" />
            <p className="text-lg">8/55 Soi Bangrae, Chalong, Phuket, Thailand</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
